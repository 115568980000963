
.card-revenue {
  background: linear-gradient(120deg, #556cdc, #128bfc, #18bef1);
  background-size: cover;
  color: color(white);

  .highlight-text {
    font-size: 1.875rem;
    font-family: $type1;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 8px;
  }

  .badge {
    background-color: rgba(color(white), .2);
    font-size: 1.125rem;
    padding: 0.5rem 1.25rem;
  }
}

.card-revenue-table {
  .revenue-item {
    border-bottom: 1px solid $border-color;
    @extend .py-3;

    &:last-child {
      border-bottom: 0;
      @extend .pb-0;
    }

    &:first-child {
      @extend .pt-0;
    }

    .revenue-desc {
      margin-right: auto;
      width: 80%;

      p {
        margin-bottom: 0;
      }
    }

    .revenue-amount {
      margin-left: auto;
      width: 40%;

      p {
        font-size: 1.25rem;
        font-family: $type1;
        font-weight: 600;
        text-align: right;

        .rtl & {
          text-align: left;
        }
      }
    }
  }
}
.proBanner {
  .purchase-popup {
    @extend .grid-margin;
    background: #fff;
    padding: 15px 20px;
    @include border-radius(3px);

    .btn {
      margin-right: 20px;
      font-weight: 500;
      color: $white;
      @include border-radius(5px);
      @include transition-duration(0.2s);

      &.download-button {
        background: rgba(249, 249, 249, 0.7);
        color: #969292;
        border: 1px solid darken(#e4e4e4,5%);
      }

      &.purchase-button {
        background-color: theme-color(info);
        color: $white;
        border: none;
        line-height: 1;
        vertical-align: middle;
      }
    }

    p {
      margin-bottom: auto;
      margin-top: auto;
      color: darken(#e4e4e4,40%);
      font-weight: 400;
      vertical-align: middle;
      line-height: 1;
    }

    i {
      vertical-align: middle;
      line-height: 1;
      margin: auto 0;
      color: darken(#e4e4e4,20%);
    }
    .bannerClose {
      cursor: pointer;
    }
  }
  &.hide {
    display: none;
  }
}

.figure-border {
  border: 2.5px dashed rgba(0,0,0,.2);
}

.overlay {
  position: fixed; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); /* Black background with opacity */
  opacity: 0.5;
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: progress; /* Add a pointer on hover */
}

.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  height:60px;
  width:60px;
  margin:0px auto;
  -webkit-animation: rotation .6s infinite linear;
  -moz-animation: rotation .6s infinite linear;
  -o-animation: rotation .6s infinite linear;
  animation: rotation .6s infinite linear;
  border-left:6px solid rgba(0,174,239,.35);
  border-right:6px solid rgba(0,174,239,.35);
  border-bottom:6px solid rgba(0,174,239,.35);
  border-top:6px solid rgba(0,174,239,.8);
  border-radius:100%;
}

@-webkit-keyframes rotation {
  from {-webkit-transform: rotate(0deg);}
  to {-webkit-transform: rotate(359deg);}
}
@-moz-keyframes rotation {
  from {-moz-transform: rotate(0deg);}
  to {-moz-transform: rotate(359deg);}
}
@-o-keyframes rotation {
  from {-o-transform: rotate(0deg);}
  to {-o-transform: rotate(359deg);}
}
@keyframes rotation {
  from {transform: rotate(0deg);}
  to {transform: rotate(359deg);}
}