
.bookCatalogContainer {
    width: 100%;
    height: 97%;
    padding: 1.5rem;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

[data-href] {
    cursor: pointer;
    color: #2196f3;
}