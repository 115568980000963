/* Sidebar */

/*
// Absolute sidebar with overlay to content
.sidebar-absolute {
  
}
*/

.screen-overlay {
  height: 100%;
  z-index: 30;
  position: fixed;
  top: 0;
  left: 0;
  opacity:0;
  visibility:hidden;
  background-color: rgba(34, 34, 34, 0.6);
  transition:opacity .2s linear, visibility .1s, width 1s ease-in;
  }
  .screen-overlay.show {
  transition:opacity .5s ease, width 0s;
  opacity:1;
  width:100%;
  visibility:visible;
  }
  
  // .offcanvas{
  // width: $offcanvas-menu-width;
  // visibility: hidden;
  // transform:translateX(-100%);
  // transition:all .2s;
  // border-radius:0; 
  // box-shadow: 0 5px 10px rgba(0,0,0, .2);
  // display:block;
  // position: fixed;
  // top: 0;
  // left: 0;
  // height: 100%;
  // z-index: 1200;
  // background-color: #fff;
  // overflow-y: scroll;
  // overflow-x: hidden;

  // .sidebar-brand-wrapper {
  //   width: auto;
  //   // height: $navbar-height;

  //   .sidebar-brand {
  //     // padding: 2.25rem 0 0.25rem 40px;
  //     text-align: center;
  //     display: inline-block;
  //     margin: 0 auto;

  //     .rtl & {
  //       padding: 20px 61px 10px 0;
  //     }

  //     img {
  //       // width: calc(#{$sidebar-width-lg} - 130px);
  //       width: calc(#{$offcanvas-menu-width} - 130px);
  //       max-width: 100%;
  //       height: 48px;
  //       margin: auto;
  //       vertical-align: middle;
  //     }
  //   }

  //   .brand-logo-mini {
  //     display: none;
  //     img {
  //       // width: calc(#{$sidebar-width-icon} - 50px);
  //       width: calc(#{$offcanvas-menu-width} - 50px);
  //       max-width: 100%;
  //       height: 35px;
  //       margin: auto;
  //     }
  //   }
  // }

  // &.show{
  //   visibility: visible;
  //   transform: translateX(0);
  //   transition: transform .2s;
  //   }

  // }
  
  

.offcanvas {

  width: $offcanvas-menu-width;
  visibility: hidden;
  transform:translateX(-100%);
  transition:all .2s;
  border-radius:0; 
  box-shadow: 0 5px 10px rgba(0,0,0, .2);
  display:block;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1200;
  background-color: #fff;
  // overflow-y: scroll;
  overflow-y: hidden;
  overflow-x: hidden;

  .sidebar-brand-wrapper {
    width: auto;
    // height: $navbar-height;

    .sidebar-brand {
      // padding: 2.25rem 0 0.25rem 40px;
      text-align: center;
      display: inline-block;
      margin: 0 auto;

      .rtl & {
        padding: 20px 61px 10px 0;
      }

      img {
        // width: calc(#{$sidebar-width-lg} - 130px);
        width: calc(#{$offcanvas-menu-width} - 130px);
        max-width: 100%;
        height: 48px;
        margin: auto;
        vertical-align: middle;
      }
    }

    .brand-logo-mini {
      display: none;
      img {
        // width: calc(#{$sidebar-width-icon} - 50px);
        width: calc(#{$offcanvas-menu-width} - 50px);
        max-width: 100%;
        height: 35px;
        margin: auto;
      }
    }
  }

  &.show{
    visibility: visible;
    transform: translateX(0);
    transition: transform .2s;
    }
    
  // min-height: calc(100vh - #{$navbar-height});
  // background: $sidebar-light-bg;
  // font-family: $type1;
  // padding: 0;
  // padding-top: 35px;
  // width: $sidebar-width-lg;
  // z-index: 11;

  // visibility: hidden;
  // transform:translateX(-100%);
  // transition:all .2s;
  // // border-radius:0; 
  // // box-shadow: 0 5px 10px rgba(0,0,0, .2);
  // // display:block;
  // // position: fixed;
  // // top: 0;
  // // left: 0;
  // // height: 100%;
  // // z-index: 1200;
  // // background-color: #fff;
  // overflow-y: scroll;
  // overflow-x: hidden;

  // &.active {
  //   visibility: visible;
  //   transform: translateX(0);
  //   transition: transform .2s;
  // }

  // transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  // -webkit-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  // -moz-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  // -ms-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;

  // .sidebar-brand-wrapper {
  //   width: auto;
  //   height: $navbar-height;

  //   .sidebar-brand {
  //     padding: 2.25rem 0 0.25rem 40px;
  //     text-align: center;
  //     display: inline-block;
  //     margin: 0 auto;

  //     .rtl & {
  //       padding: 20px 61px 10px 0;
  //     }

  //     img {
  //       // width: calc(#{$sidebar-width-lg} - 130px);
  //       width: calc(#{$offcanvas-menu-width} - 130px);
  //       max-width: 100%;
  //       height: 48px;
  //       margin: auto;
  //       vertical-align: middle;
  //     }
  //   }

  //   .brand-logo-mini {
  //     display: none;
  //     img {
  //       // width: calc(#{$sidebar-width-icon} - 50px);
  //       width: calc(#{$offcanvas-menu-width} - 50px);
  //       max-width: 100%;
  //       height: 35px;
  //       margin: auto;
  //     }
  //   }
  // }
  .nav {
    overflow: hidden;
    flex-wrap: nowrap;
    flex-direction: column;

    .nav-item {
      .collapse {
        z-index: 999;
      }

      .collapse.show,
      .collapsing {
        background: $sidebar-light-menu-active-bg;
      }

      .nav-link {
        align-items: center;
        display: flex;
        padding: $sidebar-menu-padding;
        white-space: nowrap;
        height: $nav-link-height;
        color: $sidebar-light-menu-color;

        i {
          &.menu-arrow {
            margin-left: auto;
            margin-right: 0;
            transition-duration: 0.2s;
            transition-property: transform;
            transition-timing-function: ease-in;

            &:before {
              content: "\F142";
              font-family: "Material Design Icons";
              font-size: 18px;
              line-height: 1;
              font-style: normal;
              vertical-align: middle;
              color: rgba($sidebar-light-menu-color, 0.5);
            }
          }
        }

        &[aria-expanded="true"] {
          background: $sidebar-light-menu-active-bg;

          i {
            &.menu-arrow {
              transform: rotate(90deg);
            }
          }
        }

        .menu-icon {
          margin-right: 1.25rem;
          width: $sidebar-icon-size;
          line-height: 1;
          font-size: 18px;
          color: lighten($sidebar-light-menu-icon-color, 30%);

          .rtl & {
            margin-right: 0;
            margin-left: 1.25rem;
          }
        }

        .menu-title {
          color: inherit;
          display: inline-block;
          font-size: $sidebar-menu-font-size;
          line-height: 1;
          vertical-align: middle;
          cursor: pointer;
        }

        .badge {
          margin-left: auto;
        }

        &:hover {
          color: darken($sidebar-light-menu-color, 5%);
        }
      }

      &.active {
        >.nav-link {
          color: $sidebar-light-menu-active-color;

          .menu-title,
          i {
            color: inherit;
          }
        }
      }

      &.nav-profile {
        .nav-link {
          display: flex;
          flex-direction: column;
          height: auto;

            .profile-image {
              width: 40px;
              height: 40px;

              img {
                border-radius: 100%;
                max-width: 100%;
              }
            }

            .text-wrapper {
              margin-left: 15px;

              .rtl & {
                margin-left: 0;
                margin-right: 15px;
              }

            }

            .profile-name {
              font-weight: 500;
              margin-bottom: 7px;
              line-height: 1;
            }

            .designation {
              margin-right: 3px;
              line-height: 1;
            }
            .dropdown {
              .dropdown-toggle {
                &.arrow-hide {
                  &:after {
                    display: none;
                  }
                }
              }
              
            }
            .dropdown {
              position: unset;

              .user-switch-dropdown-toggler {
                display: flex;
                flex-direction: row;
                padding: 1px 0px;
                margin-bottom: 30px;
                &:focus {
                  outline: 0;
                  box-shadow: none;
                }
              }

              .dropdown-menu {
                top: 0;
              }
            }
        }
      }
      &.not-navigation-link {
        position: relative;
      }
    }

    &:not(.sub-menu) {
      >.nav-item {
        &:hover {
          &:not(.nav-profile) {
            >.nav-link {
              background: $sidebar-light-menu-hover-bg;
              color: $sidebar-light-menu-hover-color;
            }
          }
        }
      }
    }

    &.sub-menu {
      margin-bottom: 0;
      padding: $sidebar-submenu-padding;

      .nav-item {
        .nav-link {
          color: $sidebar-light-submenu-color;
          padding: $sidebar-submenu-item-padding;
          font-size: $sidebar-submenu-font-size;
          line-height: 1;
          height: auto;

          &.active {
            color: $sidebar-light-menu-active-color;
            background: transparent;

            &:before {
              background: $sidebar-light-menu-active-color;
            }
          }
        }

        &:hover {
          >.nav-link {
            background: $sidebar-light-submenu-hover-bg;
            color: $sidebar-light-submenu-hover-color;

            &:before {
              background: $sidebar-light-submenu-hover-color;
            }
          }
        }
      }
    }
  }
}

//sidebar color variation
.sidebar-dark {
  .sidebar {
    background: $sidebar-dark-bg;

    .nav {
      .nav-item {

        .collapse.show,
        .collapsing {
          background: $sidebar-dark-menu-active-bg;
        }

        .nav-link {
          color: $sidebar-dark-menu-color;

          &[aria-expanded="true"] {
            background: $sidebar-dark-menu-active-bg;
          }

          i:not(.btn i) {
            color: $sidebar-dark-menu-icon-color;

            &.menu-arrow {
              &:before {
                color: rgba($sidebar-dark-menu-color, 0.5);
              }
            }
          }

          &:hover {
            color: darken($sidebar-dark-menu-color, 5%);
          }
        }

        &.nav-profile {
          .profile-name {
            .name {
              color: $sidebar-dark-profile-name-color;
            }

            .designation {
              color: $sidebar-dark-profile-title-color;
            }
          }

          .notification-panel {
            &:before {
              background: $sidebar-dark-profile-name-color;
            }

            >span {
              background: $sidebar-dark-menu-active-bg;

              i {
                color: color(gray-light);
              }
            }
          }
        }

        &.active {
          >.nav-link {
            color: $sidebar-dark-menu-active-color;
          }
        }

        .sidebar-sticker {
          background: $sidebar-dark-menu-active-bg;
        }
      }

      &:not(.sub-menu) {
        >.nav-item {
          &:hover {
            &:not(.nav-profile) {
              >.nav-link {
                background: $sidebar-dark-menu-hover-bg;
                color: $sidebar-dark-menu-hover-color;
              }
            }
          }
        }
      }

      &.sub-menu {
        .nav-item {
          .nav-link {
            color: $sidebar-dark-submenu-color;

            &.active {
              color: $sidebar-dark-menu-active-color;

              &:before {
                background: $sidebar-dark-menu-active-color;
              }
            }
          }

          &:hover {
            >.nav-link {
              background: $sidebar-dark-submenu-hover-bg;
              color: $sidebar-dark-submenu-hover-color;

              &:before {
                background: $sidebar-dark-submenu-hover-color;
              }
            }
          }
        }
      }
    }
  }
}

/* style for off-canvas menu*/

@media screen and (max-width: 991px) {
  .sidebar-offcanvas {
    position: fixed;
    max-height: calc(100vh - #{$navbar-height});
    top: $navbar-height;
    bottom: 0;
    overflow: hidden;
    // overflow: auto;
    right: -$sidebar-width-lg;
    -webkit-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;

    &.active {
      right: 0;
    }
  }
}
